import React, {useContext, useEffect, useState} from 'react';
import {IconButton, LinearProgress, MenuItem, TextField, Typography} from "@material-ui/core";
import {Grid} from "@material-ui/core/index";
import {useClientsScheduleService, useVendedoresService} from "services/hooks";
import {useSnackbar} from "notistack";
import {MainContext} from "contexts/main.context";
import MomentUtils from "@date-io/moment";
import {ChevronLeftRounded, ChevronRightRounded, EventRounded} from "@material-ui/icons";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {useDidMount} from "components/helpers/hooks/custom.hooks";
import {CardCliente} from "./card.cliente";
import {SimpleDialog} from "../../../helpers/dialogs/simple.dialog";
import {validateGeolocationAPI} from "components/helpers/geolocation/geolocation";
import {Checkin} from "./checkin";
import moment from "moment";

export const Revision = () => {
    const context = useContext(MainContext);

    const VendedoresService = useVendedoresService();
    const ClientsScheduleService = useClientsScheduleService();

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const [vendedores, setVendedores] = useState([]);
    const [vendedorSelected, setVendedorSelected] = useState({});
    const [date, setDate] = useState((new MomentUtils()).date(new Date()));
    const [confirmCheckinDialogOpen, setConfirmCheckinDialogOpen] = useState(false);
    const [deleteCheckinDialogOpen, setDeleteCheckinDialogOpen] = useState(false);
    const [clientSelected, setClientSelected] = useState();

    const [clients, setClients] = useState([]);
    const [visited, setVisited] = useState(0);

    const didMount = useDidMount();
    useEffect(() => loadVendedores(), []);
    useEffect(() => didMount && loadClientsSchedules(), [date, vendedorSelected]);
    useEffect(() => setVisited(clients.reduce((acc, client) => +client.visitado + acc, 0)), [clients])

    const loadClientsSchedules = () => {
        ClientsScheduleService.getClientes(
            date.format('DD/MM/YY'),
            vendedorSelected.id,
            response => setClients(response.data.result),
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    }

    const loadVendedores = () => {
        VendedoresService.getAll(
            response => {
                setVendedores(response.data.result);
                setVendedorSelected(response.data.result[0]);
            },
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    }

    // const navDetalles = idCliente => history.push(`${match.url}/${idCliente}/checkin`, {date: date.format('DD/MM/YY')});
    const openActionPopup = cliente => {
        setClientSelected(cliente);

        if (cliente.visitado)
            setDeleteCheckinDialogOpen(true);
        else
            setConfirmCheckinDialogOpen(true);
    }

    const confirmCheckin = async () => {
        // Valido que no puedan realizar checkin de días posteriores a hoy.
        if (date > moment()) {
            enqueueSnackbar(`No es posible realizar checkin para días posteriores al actual`, {variant: 'error'});
            return false;
        }

        context.addServiceRunning();
        const validationData = await validateGeolocationAPI();
        context.removeServiceRunning();

        if (!validationData.isValid) {
            enqueueSnackbar(`Error al obtener la geolocalización: ${validationData.errorMsg} Consultar a un administrador.`, {variant: 'error'});
            return;
        }

        context.addServiceRunning();
        navigator.geolocation.getCurrentPosition(position => {
                ClientsScheduleService.createCheckin(
                    vendedorSelected?.id || context.loggedUser.id || context.loggedUser.username,
                    clientSelected.id,
                    date.format('DD/MM/YY'),
                    position.coords.latitude,
                    position.coords.longitude,
                    response => {
                        context.removeServiceRunning();
                        setConfirmCheckinDialogOpen(false);
                        loadClientsSchedules();
                        enqueueSnackbar("Checkin realizado correctamente", {variant: 'success'})
                    },
                    error => enqueueSnackbar("Error al realizar el checkin", {variant: 'error'})
                )
            }
        );
    }

    const deleteCheckin = () => {
        ClientsScheduleService.deleteCheckin(
            vendedorSelected?.id || context.loggedUser.id || context.loggedUser.username,
            clientSelected.id,
            date.format('DD/MM/YY'),
            response => {
                setDeleteCheckinDialogOpen(false);
                loadClientsSchedules();
                enqueueSnackbar("Checkin eliminado correctamente", {variant: 'success'})
            },
            error => enqueueSnackbar("Error al eliminar el checkin", {variant: 'error'})
        )
    }

    return (
        <>
            <Grid container spacing={2} direction={"column"}>
                {['A', 'O'].includes(context.loggedUser.role) && (
                    <Grid item>
                        <TextField
                            select
                            fullWidth
                            className='mr-2 select'
                            value={vendedorSelected}
                            label={'Visitas del Vendedor'}
                            onChange={event => setVendedorSelected(event.target.value)}
                        >
                            {vendedores.map((vendedor, i) => (
                                <MenuItem key={i} value={vendedor}>
                                    {vendedor.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                )}
                <Grid container item justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item xs={2} className={'d-flex'} justifyContent={"center"}>
                        <IconButton
                            onClick={() => setDate((new MomentUtils()).date(date).subtract(1, "days"))}
                        >
                            <ChevronLeftRounded fontSize={"large"}/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={8} justifyContent={"center"} className={'d-flex'}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                                autoOk
                                // fullWidth
                                format="DD/MM/Y"
                                value={date}
                                label={'Visitas del día'}
                                InputProps={{
                                    style: {
                                        height: "2rem",
                                        fontSize: "18px",
                                        width: "12rem",
                                        paddingRight: "1.1rem"
                                    },
                                    endAdornment: <EventRounded/>

                                }}
                                inputProps={{
                                    style: {
                                        textAlign: "center"
                                    }
                                }}
                                onChange={moment => setDate(moment)}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={2} className={'d-flex'} justifyContent={"center"}>
                        <IconButton
                            onClick={() => setDate((new MomentUtils()).date(date).add(1, "days"))}
                        >
                            <ChevronRightRounded fontSize={"large"}/>
                        </IconButton>
                    </Grid>
                </Grid>
                {clients.length > 0 &&
                    <Grid item>
                        <LinearProgress style={{height: '0.5rem'}} variant="determinate"
                                        value={(visited) * 100 / (clients.length)}/>
                        <Typography
                            variant={"caption"}
                            color={"textSecondary"}
                        >
                            {visited} de {clients.length} clientes visitados
                        </Typography>
                    </Grid>
                }

                <Grid item>
                    {clients.length <= 0 ?
                        <Typography
                            className={'mt-5'}
                            variant={"body2"}
                            color={"textSecondary"}
                            align={"center"}
                        >
                            No hay clientes agendados para este día
                        </Typography> :
                        clients.map((client, index) =>
                            <CardCliente
                                key={index}
                                cliente={client}
                                onClick={() => openActionPopup(client)}
                            />
                        )}
                </Grid>
            </Grid>
            <SimpleDialog
                open={confirmCheckinDialogOpen}
                // maxWidth={'sm'}
                // fullWidth
                fullScreen
                title={'Realizar Checkin'}
                onCancel={() => setConfirmCheckinDialogOpen(false)}
                onConfirm={confirmCheckin}
                // confirmText={"Confirmar"}
                body={<Checkin idcliente={clientSelected?.id}/>}
            />
            <SimpleDialog
                open={deleteCheckinDialogOpen}
                // maxWidth={'sm'}
                // fullWidth
                fullScreen
                title={'Eliminar Checkin'}
                onCancel={() => setDeleteCheckinDialogOpen(false)}
                onDelete={deleteCheckin}
                body={`Eliminar el checkin del cliente ${clientSelected?.id}`}
            />
        </>
    )
}
