import BaseService from './base.service'

export default class ClientesService extends BaseService {
    getAll(fnSuccess, fnError) {
        return this.getApi().get(`/clientes`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getAllWithDirecciones(fnSuccess, fnError) {
        return this.getApi().get(`/clientes?withDirecciones`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getAllWithInactivos(fnSuccess, fnError) {
        return this.getApi().get(`/clientes?withInactivos`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getById(id, fnSuccess, fnError) {
        return this.getApi().get(`/clientes?id=${id}`)
            .then(response => {
                if (response.data.count === 1)
                    response.data.result = response.data.result[0];
                fnSuccess(response);
            })
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getByVendedor(idvendedor, fnSuccess, fnError) {
        return this.getApi().get(`/clientes?vendedor=${idvendedor}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getDireccionesEntrega(id, fnSuccess, fnError) {
        return this.getApi().get(`/clientes/direccionesEntrega?id=${id}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getDeudaPendiente(id, fnSuccess, fnError) {
        return this.getApi().get(`/clientes/deudaPendiente?id=${id}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getSaldoCuentaCorriente(id, fnSuccess, fnError) {
        return this.getApi().get(`/clientes/saldoCuentaCorriente?id=${id}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getInhabilitados(vendedor, fnSuccess, fnError) {
        return this.getApi().get(`/clientes/inhabilitados?vendedor=${vendedor}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    create(data, fnSuccess, fnError) {
        return this.getApi().post(`/clientes/ocasional`, data)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }
}
