import React, {useContext, useEffect, useState} from 'react';
import {AppBar, Box, Grid, Tab, Tabs, Toolbar} from "@material-ui/core";
import {Revision} from "./revision/revision";
import {Configuracion} from "./configuracion/configuracion";
import {Route, Switch, useHistory} from 'react-router-dom';
import SubHeader from "../../helpers/subHeader/subHeader";
import {MainContext} from "../../../contexts/main.context";
import {useLocation, useRouteMatch} from "react-router";

const basePath = '/objetivos/visitas_cliente';

export const VisitasCliente = () => {
    const [activeTab, setActiveTab] = useState('revision');

    const context = useContext(MainContext);
    const location = useLocation();
    const history = useHistory();
    const match = useRouteMatch();

    useEffect(() => {
        if (location.pathname.indexOf('configuracion') > -1)
            setActiveTab('configuracion');
    }, []);

    const changeTab = (event, tab) => {
        setActiveTab(tab);
        history.push(`${match.url}/${tab}`);
    };

    return (
        <div>
            <SubHeader title={'Visitas a Cliente'}/>
            <AppBar position="relative" elevation={0}>
                <Toolbar variant="dense" color='primary'>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Tabs
                                value={activeTab}
                                onChange={changeTab}
                                indicatorColor="secondary"
                                textColor="inherit"
                            >
                                {["A", "O"].includes(context.loggedUser.role) && (
                                    <Tab label="Configuracion" value='configuracion'/>
                                )}
                                <Tab label="Revision" value='revision'/>
                            </Tabs>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Box className='container-xl mt-3 mb-3'>
                <Switch>
                    <Route path={`${basePath}/configuracion`} component={Configuracion}/>
                    <Route path={`${basePath}`} component={Revision}/>
                </Switch>
            </Box>
        </div>
    )
}
