import React, {useEffect, useState} from 'react';
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import {VisitasCheckinMap} from "components/objetivos/visitas_cliente/revision/checkin.map";
import {BusinessRounded, PlaceRounded} from "@material-ui/icons";
import Skeleton from "@material-ui/lab/Skeleton";
import {useSnackbar} from "notistack";
import {formatDireccion} from "assets/utils";
import {useClientesService} from "services/hooks";

export function Checkin({idcliente}) {
    const ClientesService = useClientesService();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const [cliente, setCliente] = useState({});

    useEffect(() => loadCliente(), []);

    const loadCliente = () => {
        ClientesService.getById(
            idcliente,
            response => setCliente(response.data.result),
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    }

    return (
        <div>
            <Box className={'m-4'}>
                <Grid container direction={"column"} spacing={2}>
                    <Grid item>
                        <Typography variant={"h6"} className={'font-weight-normal'} align={"center"}>
                            {formatDireccion(cliente.direccion_entrega)}
                        </Typography>
                    </Grid>
                    <Grid item style={{height: '15rem', display: 'flex'}}>
                        {(cliente.latitud && cliente.longitud) ? (
                            <VisitasCheckinMap
                                cliente={cliente}
                                lat={cliente.latitud}
                                lng={cliente.longitud}
                                zoom={11}
                            />
                        ) : (
                            <Skeleton variant="rect" width={'100%'} height={'15rem'}/>
                        )}
                    </Grid>
                    <Grid item className={'d-flex mt-3'}>
                        <BusinessRounded className={'mr-3'}/>
                        <Typography
                            variant={"body1"}
                            component={"span"}
                            className={'font-weight-normal'}
                        >
                            {cliente.razsoc}
                        </Typography>
                    </Grid>
                    <Grid item className={'d-flex mb-2'}>
                        <PlaceRounded className={'mr-3'}/>
                        <Typography
                            variant={"body1"}
                            component={"span"}
                            className={'font-weight-normal'}
                        >
                            {cliente.direccion}, {cliente.localidad}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}
