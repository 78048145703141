import {enviroment} from 'enviroment';
import axios from 'axios';

export default class BaseService {
    _api;
    _baseURL;

    constructor(context) {
        const apiUrl = localStorage.getItem('apiUrl');
        this._baseURL = `${apiUrl}/${enviroment.apiVersion}`;
        this._api = axios.create({
            baseURL: this._baseURL
        });

        // Add a request interceptor
        this._api.interceptors.request.use(function (config) {
            // Do something before request is sent
            if (context)
                setTimeout(() => context.addServiceRunning(), 1000);

            return config;
        }, function (error) {
            // Do something with request error
            if (context)
                context.removeServiceRunning();

            return Promise.reject(error);
        });

        // Add a response interceptor
        this._api.interceptors.response.use(function (response) {
            // Do something with response data
            if (context)
                context.removeServiceRunning();

            return response;
        }, function (error) {
            // Do something with response error
            if (context)
                context.removeServiceRunning();

            return Promise.reject(error);
        });
    }

    getApi = () => {
        // Seteo el header de autorización (se actualiza en cada request).
        this._api.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('JWT');
        return this._api;
    };

    getBaseURL = () => this._baseURL;

    getMessageFromError = (error, defaultMessage = 'Error') => {
        console.log(error);
        let message = defaultMessage;

        if (error.response === undefined)
            message += `: el servidor no está disponible o no tiene conexión a internet.`;
        else if (error.response && error.response.data && error.response.data.message)
            message += `: ${error.response.data.message}`;

        return message;
    }
}
