import {CardChart} from "../card.chart";
import TextField from "@material-ui/core/TextField";
import {TIPOS_GRAFICO} from "../tipos.grafico";
import MenuItem from "@material-ui/core/MenuItem";
import {RankingTable} from "../charts/ranking.table";
import React, {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import {useEstadisticasService} from "../../../../../services/hooks";

export const RankingArticulosCard = ({settingsState}) => {
    const [rankingArticulos, setRankingArticulos] = useState([]);
    const [tipoRankingArticulos, setTipoRankingArticulos] = useState(TIPOS_GRAFICO[0]);

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const EstadisticasService = useEstadisticasService();

    useEffect(() => {
        EstadisticasService.getRankingArticulos(
            settingsState,
            success => setRankingArticulos(success.data.result),
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    }, [settingsState]);

    return (
        <CardChart
            title={'Ranking Artículos'}
            subtitle={tipoRankingArticulos.description}
            rightContent={
                <TextField
                    select
                    value={tipoRankingArticulos}
                    onChange={event => setTipoRankingArticulos(event.target.value)}
                >
                    {TIPOS_GRAFICO.map((tipo, index) => (
                        <MenuItem key={index} value={tipo}>{tipo.description}</MenuItem>
                    ))}
                </TextField>
            }
        >
            <RankingTable
                data={rankingArticulos}
                height={400}
                id={'codigo'}
                value={tipoRankingArticulos}
            />
        </CardChart>
    )
}
