import MomentUtils from "@date-io/moment";
import 'moment/locale/es';
import NumberFormat from "react-number-format";
import React from "react";
import PendienteIcon from "@material-ui/icons/HistoryRounded";
import ObservadaIcon from "@material-ui/icons/HighlightOffRounded";
import CheckIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import SavedIcon from "@material-ui/icons/BookmarkRounded";
import AssignmentTurnedInRounded from "@material-ui/icons/AssignmentTurnedInRounded";
import FacturacionIconYellow from "assets/img/FacturacionIconYellow.svg";
import FacturacionIconGreen from "assets/img/FacturacionIconGreen.svg";
import {AddCircleOutlineRounded, CancelRounded, CompareArrowsRounded, FormatListBulletedRounded, LocalShippingRounded, RepeatRounded} from "@material-ui/icons";
import {Tooltip, Typography} from "@material-ui/core";

export const regExpInteger = /^$|^[0-9]+$/;
export const regExpPercentage = /^$|^([0-9]{0,2})(\.[0-9]{0,2})?$|^-[0-9]{0,2}$|^100$|^-100$/;
export const regExpPrice = /^$|^[0-9]+(\.[0-9]{0,2})?$/;
export const regExpEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const calcImporteByArticulo = (cantidad = 1, precio = 0, bonificacion = 0) => {
    cantidad = (!isNaN(parseInt(cantidad))) ? parseInt(cantidad) : 0;
    // Redondeo a 2 decimales para evitar problemas con cantidades altas.
    // Notado el 11/12/2019 por Hugo
    precio = (!isNaN(parseFloat(precio))) ? Math.round(parseFloat(precio) * 100) / 100 : 0;
    bonificacion = (!isNaN(parseFloat(bonificacion))) ? parseFloat(bonificacion) : 0;

    let importe = cantidad * precio;
    importe = importe - (importe * bonificacion / 100);
    importe = isNaN(importe) ? 0 : parseFloat(importe.toFixed(2));
    return importe;
};

export const formatPrice = (number, usePrefix = false, prefix = '$') => {
    let price = '-';

    if (number === '')
        number = 0;

    number = parseFloat(number);

    if (!isNaN(number)) {
        price = number
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

        if (usePrefix)
            price = prefix + price;
    }

    // Quito los decimales si son 0
    let aPrice = price.split(',');
    price = (aPrice[1] === '00') ? aPrice[0] : aPrice[0] + ',' + aPrice[1];

    return price;
};

export const formatQuantity = number => {
    let quantity = '-';

    if (number === '')
        number = 0;

    number = parseInt(number);

    if (!isNaN(number)) {
        quantity = number
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

        return quantity;
    }
};

export const formatPercentage = number => {
    let percentage = '-';

    if (number === '')
        number = 0;

    number = parseFloat(number);

    if (!isNaN(number)) {
        percentage = number
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

        return percentage;
    }
};

export const sortNumeric = (number1, number2) => {
    number1 = parseFloat(number1);
    number2 = parseFloat(number2);
    return (number1 === number2) ? 0 : (number1 < number2) ? -1 : 1;
};

export const sortDate = (date1, date2) => {
    date1 = (new MomentUtils()).parse(date1, 'D/MM/Y');
    date2 = (new MomentUtils()).parse(date2, 'D/MM/Y');
    return (date1 === date2) ? 0 : (date1 < date2) ? -1 : 1;
};

export const toggleFullScreen = () => isFullScreen() ? exitFullScreen() : requestFullScreen();

const isFullScreen = () => (document.fullScreenElement || document.mozFullScreen || document.webkitIsFullScreen);

export const requestFullScreen = () => {
    const element = document.documentElement;
    if (element.requestFullscreen)
        element.requestFullscreen();
    else if (element.msRequestFullscreen)
        element.msRequestFullscreen();
    else if (element.mozRequestFullScreen)
        element.mozRequestFullScreen();
    else if (element.webkitRequestFullscreen)
        element.webkitRequestFullscreen();
};

const exitFullScreen = () => {
    if (document.exitFullscreen)
        document.exitFullscreen();
    else if (document.msExitFullscreen)
        document.msExitFullscreen();
    else if (document.mozCancelFullScreen)
        document.mozCancelFullScreen();
    else if (document.webkitExitFullscreen)
        document.webkitExitFullscreen();
};

export const cloneArray = (dataArray) => {
    let newData = [];
    dataArray.forEach((value) => {
        newData.push({...value})
    });
    return newData;
};

export function InputInteger(props) {
    const {inputRef, onChange, ...other} = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            decimalScale={0}
            defaultValue={0}
            isNumericString
        />
    );
}

export function InputPrice(props) {
    const {inputRef, onChange, ...other} = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={'.'}
            decimalSeparator={','}
            decimalScale={2}
            isNumericString
            prefix="$"
        />
    );
}

export function InputPercentage(props) {
    const {inputRef, onChange, ...other} = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={'.'}
            decimalSeparator={','}
            decimalScale={2}
            allowNegative={false}
            isNumericString
            suffix="%"
            isAllowed={(values => values.value === '' || values.floatValue <= 100)}
        />
    );
}

export const roundPrice = (price) => {
    price = parseFloat(price);
    price = Math.round(price * 100) / 100;
    return (Math.abs(price) < 0.05) ? 0 : price;
}

export const formatDireccion = (direccion = '') => {
    const partes = direccion.split(',');
    if (partes.length > 1)
        return <>{partes.shift()} <br/> {partes.join(', ')}</>;
    else
        return partes[0];
}

export const STATUS_COBRANZAS = ["A", "O", "I", "P", "G", "R", "S"];

export function CobranzaStatusFormatter(props) {
    const {row} = props;
    const cobranzaInfo = getCobranzaStatusInfo(row.status);
    return (
        <Tooltip title={cobranzaInfo.tooltip}>
            <div className={'d-inline-flex align-items-center'}>
                {cobranzaInfo.icon}
                <Typography
                    className={'ml-1'}
                    component={"span"}
                    variant={"body2"}
                    color={cobranzaInfo.color}
                >
                    {cobranzaInfo.description}
                </Typography>
            </div>
        </Tooltip>
    )
}

export function getCobranzaStatusInfo(status) {
    // status = P
    let ret = {
        description: "Pendiente",
        color: "textPrimary",
        icon: <PendienteIcon color={"inherit"}/>,
        tooltip: "La cobranza tiene ítems pendientes de aprobación"
    };

    // eslint-disable-next-line default-case
    switch (status) {
        case "A":
            ret.description = "Aprobada";
            ret.color = "primary";
            ret.icon = <CheckIcon color={"primary"}/>;
            ret.tooltip = "La cobranza está lista para su rendición";
            break;
        case "O":
            ret.description = "Observada";
            ret.color = "error";
            ret.icon = <ObservadaIcon color={"error"}/>;
            ret.tooltip = "La cobranza fue observada y debe ser editada";
            break;
        case "I":
            ret.description = "Ingresada";
            ret.color = "textSecondary";
            ret.icon = <CheckIcon color={"disabled"}/>;
            ret.tooltip = "La cobranza ya fue ingresada al sistema";
            break;
        case "G":
            ret.description = "Guardada";
            ret.color = "secondary";
            ret.icon = <SavedIcon color={"secondary"}/>;
            ret.tooltip = "La cobranza está a la espera de incluirse su ajuste plazo correspondiente";
            break;
        case "R":
            ret.description = "En Revisión";
            ret.color = "textPrimary";
            ret.icon = <PendienteIcon color={"inherit"}/>;
            ret.tooltip = "La cobranza debe ser revisada por un administrador"
            break;
        case "S":
            ret.description = "Rendida";
            ret.color = "textPrimary";
            ret.icon = <AssignmentTurnedInRounded style={{color: "rgb(129 129 129)"}}/>;
            ret.tooltip = "La cobranza fue rendida y está a la espera de ser ingresada"
            break;
    }

    return ret;
}

export function CotizacionStatusFormatter(props) {
    const {row} = props;
    const cotizacionInfo = getCotizacionStatusInfo(row);
    return (
        row.isprepago ?
            <Tooltip title={cotizacionInfo.tooltip}>
                <div className={'d-inline-flex align-items-center'}>
                    {cotizacionInfo.icon}
                    <Typography
                        className={'ml-1'}
                        component={"span"}
                        variant={"body2"}
                        color={cotizacionInfo.color}
                    >
                        {cotizacionInfo.description}
                    </Typography>
                </div>
            </Tooltip> :
            <></>

    )
}

export function getCotizacionStatusInfo({aprob_coti, ingre_tran, aprob_tran, recha_tran}) {
    let ret = {};

    if (aprob_coti === "A" && ingre_tran && aprob_tran) {
        ret.description = "Aprobada";
        ret.color = "primary";
        ret.icon = <img src={FacturacionIconGreen}/>;
        ret.tooltip = "La cotización fue aprobada y el pedido ya fue generado";
    }

    if (aprob_coti === "A" && ingre_tran && !aprob_tran && !recha_tran) {
        ret.description = "Esperando Transferencias";
        ret.color = "textSecondary";
        ret.icon = <img src={FacturacionIconYellow}/>;
        ret.tooltip = "Esperando las transferencias del cliente";
    }

    if (aprob_coti === "A" && !ingre_tran) {
        ret.description = "Items Faltantes";
        ret.color = "secondary";
        ret.icon = <FormatListBulletedRounded color={"secondary"}/>;
        ret.tooltip = "El vendedor debe completar los datos de las transferencias";
    }

    if (aprob_coti === '') {
        ret.description = "Nueva";
        ret.color = "textSecondary";
        ret.icon = <AddCircleOutlineRounded color={"disabled"}/>;
        ret.tooltip = "La cotización está esperando a ser aprobada por un administrador"
    }

    if (aprob_coti === 'R') {
        ret.description = "Rechazada";
        ret.color = "error";
        ret.icon = <CancelRounded color={"error"}/>;
        ret.tooltip = "La cotización fue rechazada por el administrador";
    }

    if (recha_tran) {
        ret.description = "Rechazada";
        ret.color = "error";
        ret.icon = <CancelRounded color={"error"}/>;
        ret.tooltip = "Las transferencias fueron rechazadas por el administrador";
    }

    return ret;
}

export function PedidoStatusFormatter(props) {
    const {row} = props;
    const pedidoInfo = getPedidosStatusInfo(row.estadio, row.estado);
    return (
        <Tooltip title={pedidoInfo.tooltip}>
            <div className={'d-inline-flex align-items-center'}>
                {pedidoInfo.icon}
                <Typography
                    className={'ml-1'}
                    component={"span"}
                    variant={"body2"}
                    color={pedidoInfo.color}
                >
                    {pedidoInfo.description}
                </Typography>
            </div>
        </Tooltip>
    )
}

export function getPedidosStatusInfo(estadio, estado) {
    let ret = {
        description: "Nuevo",
        color: "textSecondary",
        icon: <AddCircleOutlineRounded color={"disabled"}/>,
        tooltip: "El pedido está esperando ser incluido en una hoja de ruta"
    };

    switch (estadio) {
        case "F":
            // case "S":
            if (estado === false) {
                ret.description = "En Facturación";
                ret.color = "secondary";
                ret.icon = <img src={FacturacionIconYellow}/>;
                ret.tooltip = "El pedido está siendo facturado";
            } else {
                ret.description = "Facturado";
                ret.color = "primary";
                ret.icon = <img src={FacturacionIconGreen}/>;
                ret.tooltip = "El pedido ya fue facturado y está esperando la generación de COT";
            }
            break;
        case "D":
            ret.description = "En Distribución";
            ret.color = "textPrimary";
            ret.icon = <LocalShippingRounded color={"inherit"}/>;
            ret.tooltip = "El pedido está listo para su despacho";
            break;
        case "E":
            ret.description = "Entregado";
            ret.color = "primary";
            ret.icon = <CheckIcon color={"primary"}/>;
            ret.tooltip = "El pedido fue entregado correctamente";
            break;
        case "R":
            ret.description = "Retornado";
            ret.color = "error";
            ret.icon = <RepeatRounded color={"error"}/>;
            ret.tooltip = "El pedido no pudo ser entregado y fue retornado";
            break;
        case "H":
            ret.description = "Rechazado";
            ret.color = "error";
            ret.icon = <CancelRounded color={"error"}/>;
            ret.tooltip = "El pedido fue rechazado por el cliente";
            break;
        case "P":
            ret.description = "Entregado Parcial";
            ret.color = "secondary";
            ret.icon = <CompareArrowsRounded color={"secondary"}/>;
            ret.tooltip = "Algunos artículos del pedido no pudieron ser entregados";
            break;
        case "N":
        case "":
            if (estado === false) {
                ret.description = "Nuevo";
                ret.color = "textSecondary";
                ret.icon = <AddCircleOutlineRounded color={"disabled"}/>;
                ret.tooltip = "El pedido está esperando ser incluido en una hoja de ruta";
            } else {
                ret.description = "Facturado";
                ret.color = "primary";
                ret.icon = <img src={FacturacionIconGreen}/>;
                ret.tooltip = "El pedido ya fue facturado y está esperando la generación de COT";
            }
            break;
        case "C":
            ret.description = "Cerrado";
            ret.color = "textSecondary";
            ret.icon = <CancelRounded color={"disabled"}/>;
            ret.tooltip = "El pedido fue cerrado y ya no está disponible";
            break;
    }

    return ret;
}

export function getRoleString(role) {
    switch (role) {
        case 'A':
            return 'Administrador/a';
        case 'O':
            return 'Operador/a';
        case 'S':
            return 'Supervisor/a';
        case 'V':
            return 'Vendedor/a';
        case 'C':
            return 'Cliente';
        default:
            return 'Sin rol asignado';
    }
}

export const defaultContacts = [{
    id: 'ROLE_A',
    description: 'Administradores',
    type: 'Grupos'
}, {
    id: 'ROLE_S',
    description: 'Supervisores',
    type: 'Grupos'
}, {
    id: 'ROLE_V',
    description: 'Vendedores',
    type: 'Grupos'
}, {
    id: 'ROLE_O',
    description: 'Operadores',
    type: 'Grupos'
}];

export const formatObjetivoValor = (valor, unidad) => {
    if (parseInt(valor) === -1)
        return 'N/A';

    let formatted;

    switch (unidad) {
        case 'QTY':
            formatted = formatQuantity(valor);
            break;
        case 'IMP':
            formatted = formatPrice(valor, true);
            break;
        default:
            formatted = valor;
    }

    return formatted;
}

export const formatObjetivoUnidad = unidad => {
    let formatted;

    switch (unidad) {
        case 'QTY':
            formatted = 'unid.';
            break;
        case 'IMP':
            formatted = 'imp.';
            break;
        default:
            formatted = unidad;
    }

    return formatted;
}

export const downloadFile = (file, filename) => {
    const fileURL = URL.createObjectURL(file);
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = fileURL;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(fileURL);
}

export const isPartialPayment = document => {
    // Calculo si es pago parcial considerando error de redondeo.
    const importe = parseFloat(document.importe);
    const pendiente = parseFloat(document.pendiente);
    return (importe <= pendiente) && Math.abs(importe - pendiente) > 0.1;
}

export const refPinGreyColor = "#e2e2e2";
export const refPinDarkGreyColor = "#848484";
export const refPinRedColor = "#ff3a3a";
export const refPinDarkRedColor = "#8d1b14";
export const refPinYellowColor = "#ffb753";
export const refPinOrangeColor = "#ff8418";
export const refPinBlackColor = "#282828";
export const refPinGreenColor = "#5aaca1";
export const refPinDarkGreenColor = "#009188";
export const refPinBlueColor = "#429fca";

export const armadoPartSuffixes = ["PROMO", "PCAJA", "PCSEM"];

export const tiposCobranzaDigitalMust = ['CHE'];
export const tiposCobranzaDigitalOptional = ['TRF', 'AEF', 'RSS', 'RIV', 'RGA', 'RIB', 'CAN'];
export const isCobranzaDigital = (itemsDeCobranza) => {
    itemsDeCobranza = itemsDeCobranza.map(item => item.tipo);

    // Cada tipo de cobranza obligatorio está presente en los items.
    const mustTypesPresent = tiposCobranzaDigitalMust.every(tipo => itemsDeCobranza.includes(tipo));

    // Cada item es de un tipo obligatorio o opcional.
    const everyItemIsMustOrOptional = itemsDeCobranza.every(item => tiposCobranzaDigitalMust.includes(item) || tiposCobranzaDigitalOptional.includes(item));

    return mustTypesPresent && everyItemIsMustOrOptional;
}

export const detectMimeType = (b64) => {
    if (!b64) return "";
    let signatures = {
        JVBERi0: "pdf",
        R0lGODdh: "image",
        R0lGODlh: "image",
        iVBORw0KGgo: "image",
        "/9j/": "image"
    };

    for (let s in signatures) {
        if (b64.indexOf(s) === 0) {
            return signatures[s];
        }
    }
}