import {CardChart} from "../card.chart";
import TextField from "@material-ui/core/TextField";
import {TIPOS_GRAFICO} from "../tipos.grafico";
import MenuItem from "@material-ui/core/MenuItem";
import {RankingTable} from "../charts/ranking.table";
import React, {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import {useEstadisticasService} from "../../../../../services/hooks";

export const RankingClientesCard = ({settingsState}) => {
    const [rankingClientes, setRankingClientes] = useState([]);
    const [tipoRankingClientes, setTipoRankingClientes] = useState(TIPOS_GRAFICO[0]);

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const EstadisticasService = useEstadisticasService();

    useEffect(() => {
        EstadisticasService.getRankingClientes(
            settingsState,
            success => setRankingClientes(success.data.result),
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    }, [settingsState]);

    return (
        <CardChart
            title={'Ranking Clientes'}
            subtitle={tipoRankingClientes.description}
            rightContent={
                <TextField
                    select
                    value={tipoRankingClientes}
                    onChange={event => setTipoRankingClientes(event.target.value)}
                >
                    {TIPOS_GRAFICO.map((tipo, index) => (
                        <MenuItem key={index} value={tipo}>{tipo.description}</MenuItem>
                    ))}
                </TextField>
            }
        >
            <RankingTable
                data={rankingClientes}
                height={400}
                id={'nombre'}
                value={tipoRankingClientes}
            />
        </CardChart>
    )
}
